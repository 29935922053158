import { en } from "vuetify/lib/locale";

export default {
	...en,

	header: {
		title: 'Akun {brand}'
	},

	error: {
		server: 'Kesalahan server, silakan coba lagi nanti'
	},

	signin: {
		alert: {
			access: {
				google: 'Gagal masuk menggunakan Akun Google Anda',
				facebook: 'Gagal masuk menggunakan Akun Facebook Anda'
			}
		},
		orother: 'atau Masuk dengan',
		identifier: {
			title: 'Login',
			subtitle: 'Gunakan Akun {brand} Anda',
			continue: 'Lanjutkan ke {app}',
			form: {
				identifier: {
					label: 'Email atau nama pengguna',
					error: {
						required: "Masukkan email atau nama pengguna",
						notfound: "Tidak dapat menemukan Akun {brand} Anda",
						server: "Kesalahan server, silakan coba lagi nanti"
					}
				},
				private: 'Bukan komputer Anda? Gunakan jendela penjelajahan rahasia untuk login.'
			},
			action: {
				signup: 'Buat akun',
				next: 'Berikutnya'
			}
		},
		password: {
			title: 'Selamat datang',
			form: {
				password: {
					label: 'Masukka sandi Anda',
					error: {
						required: "Masukkan sebuah password",
						unauthorized: "Salah sandi. Coba lagi atau klik Lupa sandi untuk mereset.",
						notfound: "Tidak dapat menemukan Akun {brand} Anda",
						server: "Kesalahan server, silakan coba lagi nanti"
					}
				}
			},
			action: {
				'forgot-password': 'Lupa sandi?',
				next: 'Berikutnya'
			}
		},
		suspended: {
			title: 'Akun {brand} Anda ditangguhkan',
			subtitle: "Akun {brand} {email} sekarang ditangguhkan. Sepertinya itu digunakan dengan cara yang melanggar kebijakan {brand}.\r\n\r\nKami memahami bahwa akun Anda penting bagi Anda. Jadi jika menurut Anda ini adalah kesalahan, silakan hubungi support@botika.online",
			action: {
				next: 'Coba hubungi'
			}
		},
		verify: {
			title: 'Verifikasi alamat email Anda',
			subtitle: "Untuk menggunakan Akun {brand} Anda, harap verifikasi alamat email Anda: {email}",
			receive: 'Email dengan kode verifikasi baru saja dikirim ke {email}',
			form: {
				code: {
					label: 'Memasukkan kode',
					error: {
						required: "Memasukkan kode",
						minlength: "Jumlah digit salah. Coba lagi",
						maxlength: "Jumlah digit salah. Coba lagi",
						numeric: "Kode hanya memiliki angka. Coba lagi",
						unauthorized: "Kode telah kedaluwarsa. Coba lagi",
						notfound: "Salah kode. Coba lagi",
						server: "Kesalahan server, silakan coba lagi nanti"
					}
				}
			},
			action: {
				sendcode: 'Kirim ulang kode',
				sendcodein: 'Kirim ulang kode dalam {time}s',
				next: 'Berikutnya'
			}
		},
		success: {
			title: 'Anda sudah login',
			subtitle: 'Anda telah berhasil masuk ke akun Anda',
			action: {
				next: 'Lanjutkan'
			}
		}
	},

	'email-verify': {
		request: {
			title: 'Verifikasi alamat email Anda',
			subtitle: 'Harap verifikasi alamat email untuk {email}',
			action: {
				next: 'Verifikasi'
			}
		},
		success: {
			title: 'Alamat email Anda telah diverifikasi',
			subtitle: 'Email Anda telah diverifikasi, sekarang Anda dapat menggunakan Akun {brand} Anda',
			action: {
				next: 'Lanjutkan'
			}
		}
	},

	'forgot-password': {
		request: {
			title: 'Pemulihan akun',
			subtitle: 'Ini membantu menunjukkan bahwa akun ini benar-benar milik Anda',
			form: {
				email: {
					label: 'Alamat email',
					error: {
						required: "Masukkan alamat email",
						email: "Masukkan alamat email yang valid",
						notfound: "Alamat email ini tidak cocok dengan yang Anda berikan. Coba lagi.",
						server: "Kesalahan server, silakan coba lagi nanti"
					}
				},
				hint: 'Silakan masukkan email yang Anda gunakan untuk masuk {brand}'
			},
			action: {
				signin: 'Login',
				next: 'Berikutnya'
			}
		},
		verify: {
			title: 'Pemulihan akun',
			subtitle: 'Email dengan kode verifikasi baru saja dikirim ke {email}',
			form: {
				code: {
					label: 'Memasukkan kode',
					error: {
						required: "Memasukkan kode",
						minlength: "Jumlah digit salah. Coba lagi",
						maxlength: "Jumlah digit salah. Coba lagi",
						numeric: "Kode hanya memiliki angka. Coba lagi",
						unauthorized: "Kode telah kedaluwarsa. Coba lagi",
						notfound: "Salah kode. Coba lagi",
						server: "Kesalahan server, silakan coba lagi nanti"
					}
				}
			},
			action: {
				sendcode: 'Kirim ulang kode',
				sendcodein: 'Kirim ulang kode dalam {time}s',
				next: 'Berikutnya'
			}
		}
	},

	'change-password': {
		request: {
			title: 'Ganti kata sandi',
			subtitle: 'Buat kata sandi baru yang kuat yang tidak Anda gunakan untuk situs web lain',
			form: {
				password: {
					label: 'Buat kata sandi',
					error: {
						required: "Masukan kata sandi",
						minlength: "Gunakan 8 karakter atau lebih untuk kata sandi Anda",
						server: "Kesalahan server, silakan coba lagi nanti"
					}
				},
				passconf: {
					label: 'Konfirmasi',
					error: {
						required: "Konfirmasi password Anda",
						confirm: "Kata sandi itu tidak cocok. Coba lagi.",
						server: "Kesalahan server, silakan coba lagi nanti"
					}
				}
			},
			action: {
				next: 'Berikutnya'
			}
		},
		success: {
			title: 'Kata sandi Anda telah diubah',
			subtitle: 'Kata sandi Anda telah berhasil diubah. Gunakan kata sandi baru Anda untuk masuk',
			action: {
				next: 'Login'
			}
		}
	},

	signup: {
		caption: 'Satu akun untuk semua layanan {brand}',
		alert: {
			verify: "Silakan lengkapi data berikut, untuk melanjutkan login"
		},
		request: {
			title: 'Buat Akun {brand}',
			continue: 'Lanjutkan ke {app}',
			form: {
				firstname: {
					label: 'Nama depan',
					error: {
						required: "Masukkan nama depan",
						server: "Kesalahan server, silakan coba lagi nanti"
					}
				},
				lastname: {
					label: 'Nama belakang',
					error: {
						required: "Masukkan nama belakang",
						server: "Kesalahan server, silakan coba lagi nanti"
					}
				},
				username: {
					label: 'Nama pengguna',
					hint: "Bisa pakai huruf & angka",
					error: {
						required: "Masukkan Nama pengguna",
						unique: "Nama pengguna itu sudah terpakai. Coba yang lain.",
						minlength: "Gunakan minimal 5 karakter",
						maxlength: "Gunakan maksimal 100 karakter",
						alphanum: "Maaf, hanya huruf (a-z), dan angka (0-9), yang diperbolehkan.",
						server: "Kesalahan server, silakan coba lagi nanti"
					}
				},
				email: {
					label: 'Alamat email anda',
					hint: "Anda harus mengonfirmasi bahwa email ini milik Anda.",
					verify: "Email ini telah diverifikasi",
					error: {
						required: "Masukkan alamat email",
						unique: "Alamat email itu diambil. Coba yang lain.",
						maxlength: "Gunakan maksimal 120 karakter",
						email: "Harap gunakan alamat email yang valid.",
						server: "Kesalahan server, silakan coba lagi nanti"
					}
				},
				phone: {
					label: 'Nomor telepon',
					error: {
						required: "Masukkan nomor telepon",
						unique: "Nomor telepon itu diambil. Coba yang lain.",
						minlength: "Gunakan minimal 6 karakter",
						maxlength: "Gunakan maksimal 20 karakter",
						valid: "Silahkan masukkan nomor telepon yang benar",
						server: "Kesalahan server, silakan coba lagi nanti"
					}
				},
				referral: {
					label: 'Kode referensi',
					error: {
						alphanum: "Maaf, hanya huruf (a-z), dan angka (0-9), yang diperbolehkan.",
						minlength: "Gunakan minimal 6 karakter",
						maxlength: "Gunakan maksimal 40 karakter",
						notfound: "Kode referensi salah. Coba lagi",
						server: "Kesalahan server, silakan coba lagi nanti"
					}
				},
				password: {
					label: 'Sandi',
					hint: 'Gunakan 8 karakter atau lebih',
					error: {
						required: "Masukan kata sandi",
						minlength: "Gunakan 8 karakter atau lebih untuk kata sandi Anda",
						server: "Kesalahan server, silakan coba lagi nanti"
					}
				},
				passconf: {
					label: 'Konfirmasi',
					error: {
						required: "Konfirmasi password Anda",
						confirm: "Kata sandi itu tidak cocok. Coba lagi.",
						server: "Kesalahan server, silakan coba lagi nanti"
					}
				}
			},
			action: {
				signin: 'Login',
				next: 'Berikutnya'
			}
		},
		success: {
			title: 'Akun {brand} Anda telah dibuat',
			subtitle: 'Akun {brand} Anda telah berhasil dibuat. Gunakan Akun {brand} Anda untuk masuk',
			action: {
				next: 'Login'
			}
		}
	},

	rejected: {
		server: {
			title: 'Ada yang salah',
			subtitle: 'Maaf, ada yang tidak beres. Coba lagi.',
			action: {
				next: 'Coba lagi'
			}
		},
		cookie: {
			title: 'Cookie dinonaktifkan',
			subtitle: 'Cookie browser dinonaktifkan. Pastikan cookie diaktifkan dan coba lagi.',
			action: {
				next: 'Coba lagi'
			}
		},
		token: {
			title: 'Token kedaluwarsa',
			subtitle: 'Sesi Anda berakhir karena token telah kedaluwarsa. Coba lagi.',
			action: {
				next: 'Coba lagi'
			}
		},
		'404': {
			title: '404. Itu kesalahan.',
			subtitle: 'URL yang diminta tidak ditemukan di server ini. Itu saja yang kami tahu.',
			action: {
				next: 'Coba lagi'
			}
		}
	},

	footer: {
		language: 'Indonesia',
		privacy: 'Privasi',
		terms: 'Persyaratan'
	}
}
